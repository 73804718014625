import moment from 'moment'


jQuery(document).ready(function ($) {

  // NAVIGATION
  $("#home-hero").show();

  // This function hides all sections, then fades in the target
  function showSection(target) {
    $("section").hide();
    $(target).fadeIn("slow");
  }

  // Keep your existing toggleNavigation if you want “active” classes
  function toggleNavigation(activeElement) {
    $("#home-hero").toggleClass("active", activeElement === "#home-hero");
    $("#music").toggleClass("active", activeElement === "#music");
    $("#video").toggleClass("active", activeElement === "#video");
    $("#tour").toggleClass("active", activeElement === "#tour");
    $("#newsletter").toggleClass("active", activeElement === "#newsletter");
    $("#promo").toggleClass("active", activeElement === "#promo");
  }



  // NAV EVENT LISTENERS
  $(".set-home-hero").on('click', function (e) {
    e.preventDefault();
    showSection("#home-hero");
    toggleNavigation("#home-hero");
    $('body').removeClass("active");
    $('.line').removeClass("internal");
  });

  $(".set-music").on('click', function (e) {
    e.preventDefault();
    showSection("#music");
    toggleNavigation("#music");
    $('body').addClass("active");
    $('.line').addClass("internal");
  });

  $(".set-video").on('click', function (e) {
    e.preventDefault();
    showSection("#video");
    toggleNavigation("#video");
    $('body').addClass("active");
    $('.line').addClass("internal");
  });

  // $(".set-merch").on('click', function (e) {
  //   e.preventDefault();
  //   showSection("#merch");
  //   toggleNavigation("#merch");
  //   $('body').addClass("active");
  //   $('.line').addClass("internal");
  // });

  $(".set-tour").on('click', function (e) {
    e.preventDefault();
    showSection("#tour");
    toggleNavigation("#tour");
    $('body').addClass("active");
    $('.line').addClass("internal");
  });

  $(".set-newsletter").on('click', function (e) {
    e.preventDefault();
    showSection("#newsletter");
    toggleNavigation("#newsletter");
    $('body').addClass("active");
    $('.line').addClass("internal");
  });

  $(".set-promo").on('click', function (e) {
    e.preventDefault();
    showSection("#promo");
    toggleNavigation("#promo");
    $('body').addClass("active");
    $('.line').addClass("internal");
  });


  // MOBILE MENU TOGGLE 
  $('.mobile-menu-icon').on('click', function () {
    $("header").toggleClass('active')
    $(".mobile-wrapper").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-item a, .nav-item, .set-home-hero').on('click', function () {
    if ($('header').hasClass('active')) {
      $("header").toggleClass('active')
      $(".mobile-wrapper").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })


  // TOUR
  $.ajax({
    url: `https://rest.bandsintown.com/artists/id_15563236/events?app_id=45PRESS_damiano_david`,
    method: 'GET',
    dataType: 'json',
    error: () => alert('Error fetching events!'),
    success: data => {
      const eventsContainer = $('#tour-dates');

      if (data.length) {
        // Generate HTML for each event and append to the events container
        const html = data.map(event => generateEventHtml(event)).join('');
        eventsContainer.html(html);

        // Hide the "toggle dates" button if fewer than 9 events are available
        if (data.length < 9) {
          $("#toggle-dates").hide();
        }
      } else {
        // Display message if no events are found
        eventsContainer.html('<span class="no-events">Check back soon for new shows!</span>');
      }
    }
  });

  // Helper function to generate HTML for each event
  function generateEventHtml(event) {
    const eventDate = moment(event.datetime).format('MMM DD');
    const venueName = event.venue.name;
    const venueRegion = event.venue.region !== null && event.venue.region !== '' ? event.venue.region : event.venue.country;
    const venueLocation = event.venue.city + ', ' + venueRegion;


    const offersHtml = event.offers.map(offer =>
      `<a href="${offer.url}" target="_blank" class="link btn">${offer.type}</a>`
    ).join('');

    return `
    <div class="event-group">
      <div class="event-date">${eventDate}</div>
      <div class="event-venue">${venueName}</div>
      <div class="event-location">${venueLocation}</div>
      <div class="event-links">${offersHtml}</div>
    </div>
  `;
  }



  // FORM SUBMIT
  $("#damiano-david-newsletter-code").on("submit", function (e) {
    e.preventDefault();

    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        alert("Submitted!");
      },
      error: function (err) {
        // Do things when submission has an error.
        alert("An error has occured!");
      }
    });
  });

  // Check if the URL has a hash and show the corresponding section
  if (window.location.hash) {
    let target = window.location.hash;
    if ($(target).length) {
      showSection(target);
      toggleNavigation(target);
      $('body').addClass("active");
      $('.line').addClass("internal");
    }
  }

});